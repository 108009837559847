@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

*{
    font-family: 'Poppins', sans-serif !important;
}
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content {
    // overwrite inline styles
    // --offset-bottom: auto!important;
    position: relative;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

//   .withoutHeight {
//     height: 100%;
//     position: relative;
// }

//   .withoutHeight .footer-sec {
//     position: relative;
//     width: 100%;
//     bottom: -25px;
//     left: 0;
// }



.start-section{
    height: 65vh;
}

// app-footer{
//     height: 40vh;
// }

ion-button {
    --border-radius: 10px;
    --box-shadow: none;
      text-transform: capitalize;
}

.form-label {
    margin-bottom: 0.5rem;
    color: var(--greyBg);
    font-size: 16px;
}

.form-control::placeholder {
    color: var(--red) !important;
    opacity: 1;
    font-size: 20px;
}

.form-horizontal .form-control:focus{
    box-shadow: none;
    outline: 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex{
    background-color: var(--white);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before{
    background-color: var(--white)
}

.mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px;
    font-weight: 400;
}

.mat-checkbox-inner-container {
    height: 20px !important;
    width: 20px !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--blue);
}

.mat-radio-checked .mat-radio-inner-circle {
    background-color: var(--blue) !important;
}

// mat-radio-group.mat-radio-group {
//     display: inline-flex;
// }

.mat-radio-button {
    width: 35%;
    margin-bottom: 10px;
}

.mat-checkbox{
    margin-bottom: 6px;
    color: var(--greyBg);
    font-size: 16px;
    font-weight: 500;
}

.back-btn {
    --border-color: var(--greyBg);
    --color: var(--greyBg);
    background-color: var(--white);
    border-radius: 15px;
}

.float-btn {
    --border-radius: 30px;
}

.createModal {
    position: relative !important;
    .mat-dialog-container {
        padding: 20px !important;
        overflow: initial;
        height: 600px;
        overflow-y: scroll;
        border-radius: 15px;
        border: 20px solid #fff;
    }
}

.timerModal, .licenseModal, .confirmModal{
    position: relative !important;
}

.confirmModal .mat-dialog-container {
        padding: 20px !important;
        overflow: initial;
        height: 600px;
        overflow-y: scroll;
        border-radius: 15px;
        border: 20px solid #fff;
        text-align: center;
        width: 100%;
        margin: 0 auto;
}


.timerModal .mat-dialog-container, .licenseModal .mat-dialog-container {
    text-align: center;
    padding: 20px !important;
    overflow: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    // height: 600px;
    // overflow-y: scroll;
}

.timerModal .close-float-btn, .createModal .close-float-btn, .licenseModal .close-float-btn, .createModal .close-float-btn, .confirmModal .close-float-btn{
    background: var(--blue);
    padding: 0;
    width: 50px;
    height: 50px;
    opacity: 1;
    color: #000;
    font-size: 42px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -20px !important;
    right: -20px !important;
    line-height: 25px;
    position: absolute;
    color: #fff;
    z-index: 9;
}

.timerModal.close-float-btn:focus, .createModal.close-float-btn:focus, .licenseModal .close-float-btn:focus, .createModal.close-float-btn:focus, .confirmModal.close-float-btn:focus {
    outline: unset !important;
}

.timerModal ion-img {
    width: 30%;
    margin: 20px auto;
}

.licenseModal .license-image {
    border: 4px solid var(--white);
    border-radius: 15px;
    overflow: hidden;
    width: 96%;
    margin: 20px auto;
    border-radius: url('assets/img/scanner.png');
}

.licenseModal .license-image ion-img{
    width: min(400px, 80%);
    margin: 0 auto;
}

.licenseModal ion-img.capture-icon{
    width: 15%;
    display: inline-block;
    vertical-align: middle;
}

ion-row{
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-msg-line{
    color: var(--red);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

.success-msg-line{
    color: var(--success);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.success-msg-line ion-img {
    width: 6% !important;
    display: inline-block;
    vertical-align: middle;
    margin: 0 !important;
}
.error-msg-line ion-img {
    width: 1% !important;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px !important;
}

.error-msg-line ion-img.insurance-capture-image{
    display: inline-block;
    width: 8% !important;
    vertical-align: middle;
}

.success-msg-line .tick-mark {
    width: 3% !important;
    margin-right: 10px !important;
}

// .createModal {height: 90% !important;margin-top:80px !important;}
// .createModalCategories {
//     margin-top: 30px;
//     .mat-dialog-container {
//         padding: 20px !important;
//         position: relative;
//         height: 630px;
//         overflow-y: auto;
//         .close-float-btn {
//             background: #f49b17;
//             padding: 0;
//             width: 25px;
//             height: 25px;
//             opacity: 1;
//             color: #000;
//             font-size: 30px;
//             font-weight: 300;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-radius: 25px;
//             top: -10px !important;
//             right: -10px !important;
//             position: absolute;
//         }
//         .close-float-btn:focus {
//             outline: unset !important;
//         }
//     }
// }


@media (min-width:1180px) and (max-width:1366px){
    .licenseModal{
        width:70% !important;
        // margin-top: 200px !important;
        height: 80% !important;
    }

    .start-section{
        height: 63vh;
    }
    
    canvas {
        height: 150px;
    }
}

@media (min-width:768x) and (max-width:1024px){
    .licenseModal{
        width:70% !important;
        // margin-top: 200px !important;
        height: 80% !important;
    }

    .start-section{
        height: 60vh;
    }


}


@media (min-width:580px) and (max-width:767px){
    .createModal {
        height: 80% !important;
        margin-top: 95px !important;
    }

    .licenseModal{
        width: 80% !important;
        margin-top: 200px !important;
        height: 60% !important;
    }

    .timerModal{
        margin-top: 200px !important;
        height: 70% !important;
        width: 70% !important;
    }

}

